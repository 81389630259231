.AreaWrong {
  &__Content {
    font-family: 'ShantelleSans';
    padding: 0 2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 18vw;
  }

  &__Row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__Icon {
    margin-right: 3%;
  }

  &__Text {
    font-size: 1.2em;
    text-align: left;
    width: 100%;

    &--center {
      text-align: center;
    }

    &--larger {
      font-size: 1.6em;
      margin-top: 3%;
    }
  }

  &__Hint {
    display: flex;
    flex-direction: row;
    text-align: left;
    background-color: white;
    padding: 1em;
    transform: rotateZ(-2deg);
    filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.1));
    margin-bottom: -14%;

    & img {
      width: 14%;
      display: block;
      margin: 0 4%;
    }

    &DescTitle {
      font-family: 'LondrinaRegular';
      font-weight: normal;
      color: #59194B;
      font-size: 2.2em;
    }

    &DescContent {
      font-size: 1.4em;
    }
  }
}