.DebriefChartLayout {
    &__Container {
        display: flex;
        flex-direction: row;
        gap: 20px;
        height: 100%;
        align-items: stretch;
        margin-bottom: 40px;
        position:relative;
    }
    &__Chart {
        flex-grow: 1;
        min-height: 100%;
    }
    &__Sidebar {
        min-height: calc(80vh);
    }
    &__Loading {
        position:absolute;
        left: -10px;
        top: -10px;
        right: -10px;
        bottom: -10px;
        padding-top: 200px;
        color: white;
        font-family: "ShantelleSans";
        text-align: center;
        font-size: 30px;
        background-color: rgba(0, 0, 0, .4);
    }
}