$background: #e1e6eb;
$background-dark: #292F36;

$primary: #59194B;
$danger: #d1352a;
$disabled: #8a8a8a;

$page-width: 1400px;
$page-padding: 20px;

$font-size-xl: 36px;
$font-size-large: 22px;
$font-size-medium: 18px;
$fonr-size-small: 12px;

$shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
$radius: 0px;

$table-header-background: #FFFDF0;
$table-row-background: white;

$status-ok: #71AC9E;
$status-inactive: #b8afa7;
$status-deactivated:#B2373D;
