@import '../../style/variables';

.SimplePaginator {
    &__Container {
        max-width: fit-content;
        display: inline-block;
    }
    &__Content {
        display:flex;
        align-items: center;
        justify-content: flex-end;
        background-color: $background;
        padding: 4px;
        border-radius: $radius;
    }
    &__Back {
        display: block;
    }
    &__Forward {
        display:block;
    }
    &__Current {
        font-size: $font-size-large;
        margin: 0px 12px;
        color: $primary;
    }
    &__Total {
        color: gray;
    }
}