.SplashScreen {
    &__Container {
        position: absolute;
        z-index: 10;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        transition: all .4s ease-in;
        display: flex;
        justify-content: center;
        align-items: center;
        &--hidden {
            opacity: 0;
            pointer-events: none;
        }
    }
    &__Content {
        color: white;
        font-weight: bolder;
    }
    &__Icon {
        width: 15vw;
        aspect-ratio: 1;
    }
}