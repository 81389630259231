@import '../../style/variables';

.SessionsLayout {
    &__Container {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        text-align: left;
        font-family: 'ShantelleSans';
    }
    
    &__Body {
        flex-grow: 1;
        // background-color: $background;
    }

    &__Footer {
        background-color: $primary;
        color: white;
        display: flex;
        justify-content: space-between;
        padding: 20px;
    }
}