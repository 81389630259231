.GameInput {
  &__Container {
    position: relative;
    width: 15vw;
    aspect-ratio: 5;
  }

  &__Background {
    position: absolute;
    left: 0;
    top: 0;
  }

  &__Input {
    position: absolute;
    left: 0;
    top: 0;
    width: 13vw;
    margin-left: 1vw;
    aspect-ratio: 5;
    background: transparent;
    padding-left: 0;
    font-family: "RobotoSlab";
    font-size: 1.5em;
    border: 0;
    text-align: center;

    &:active,
    &:focus,
    &:focus-visible {
      outline: 0;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
}