@import '../../style/variables';

.SessionCreatePage {
  &__Container {
    max-width: $page-width;
    margin: auto;
    padding-top: 20px;
    padding-left: $page-padding;
    padding-right: $page-padding;
  }

  &__Title {
    margin-top: 40px;
  }

  &__Partecipants {
    margin-bottom: 40px;
  }

  &__NoPartecipants {
    font-size: $font-size-medium;
    padding: 40px;
    display: flex;
    justify-content: center;
  }
}