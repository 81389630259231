@import '../../../style/variables.scss';


.ToggleInput {
  input {
    opacity: 0;
  }
    &__Container {
      font-size: 20px;
        display: flex;
        align-items: center;
      }
      
      &__Switch {
        position: relative;
        display: inline-block;
        width: 80px;
        height: 40px;
      }
      
      &__Slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        border-radius: 20px;
        transition: 0.4s;
      }
      
      &__Slider:before {
        position: absolute;
        content: '';
        height: 36px;
        width: 36px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        border-radius: 50%;
        transition: 0.4s;
      }
      
      &__Slider--checked {
        background-color: $primary;
      }
      
      &__Slider--checked:before {
        transform: translateX(40px);
      }
      
      &__Label {
        margin-left: 10px;
      }
}
