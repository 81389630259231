@import '../../style/variables';

.Dialog {
  &__Container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    background-color: rgba(0, 0, 0, 0.2);
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  &__Card {
    min-width: 600px;
    font-size: $font-size-medium;
    text-align: center;
  }

  &__Footer {
    background-color: $background;
    padding: 6px;
    width: fit-content;
    margin-left: auto;
    border-radius: $radius;

    button:not(:first-child) {
      margin-left: 8px;
    }
  }
}