.ProfilationThirdPage {
  &__Container {
    display: flex;
    width: 80%;
    height: 80%;
    flex-direction: row;
    padding-top: 0%;
    flex-wrap: wrap;
  }

  &__Field {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__Label {
    font-size: 1.8em;
    font-family: 'LondrinaRegular';
    color: #6B6B6B;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  &__FieldHalf {
    width: 50%;
  }
}