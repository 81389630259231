.NoChallengePage {
    &__Container {
        height: 100%;
        width: 100%;
        position: relative;
    }
    &__Image {
        width: 80%;
        position: absolute;
        left: 50%;
        top: 50%;
        translate: -50% -50%;
    }
    &__MessageContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    &__Message {
        font-family: 'ShantelleSans';
        font-size: 2em;
        margin-top: 4%;
    }
    &__Icon>img {
        width: 4vw;
    }
}