@import '../../style/variables';

.SessionsPage {
    &__Container {
        max-width: $page-width;
        margin: auto;
        padding-top: 20px;
        padding-left: $page-padding;
        padding-right: $page-padding;
    }
    &__Title {
        margin-top: 12px;
    }
}