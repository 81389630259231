.DialogBox {
    &__Container {
        height:100%;
    }
    &__Header {
        width: 100%;
        display: block;
        z-index: 2;
        position: relative;
        &Content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            &Title {
                text-align: center;
                width: 100%;
                margin-top: 4%;
                font-family: "LondrinaRegular";
                font-size: 2em;
                color: white;
            }
        }
    }

    &__Wrapper {
        width: 100%;
        height:100%;
        position: relative;
        &Content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            padding-top: 0;
            height: 100%;
        }
        img {
            height:100%;
            width: 100%;
        }
    }
    &__WrapperSmall {
        height: 20vw;
        &>img {
            height:20vw;
            width: 100%;
        }
    }
    &__Actions {
        margin: auto;
        width: fit-content;
        margin-top: -4%;
    }
    &__ExitIcon {
        position: absolute;
        right: -5%;
        top: -8%;
        cursor: pointer;
        width: 2vw;
    }
}