@import '../../style/variables';

.Table {
  &__Container {
    table {
      width: 100%;
      border: none;
      border-collapse: collapse;

      thead tr {
        background-color: rgba(89, 25, 75, .1);
      }

      tbody tr:not(:first-child) {
        background-color: $table-row-background;
      }

      tbody tr:not(:first-child):nth-child(even) {
        background-color: rgba(89, 25, 75, .06);

      }

      th {
        padding: 1rem;
        font-weight: 700;
      }

      td {
        padding: 1rem;
      }
    }
  }
}