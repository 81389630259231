.Box {
    &__Container {
        
    }
    &__Header {
        width: 100%;
        aspect-ratio: 7;
        display: block;
        z-index: 2;
        position: relative;
        &Content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            &Title {
                text-align: center;
                width: 100%;
                margin-top:3%;
                font-family: "LondrinaRegular";
                font-size: 2em;
                color: white;
            }
        }
    }

    &__Wrapper {
        width: 35vw;
        aspect-ratio: 2.9;
        margin-top: -2vh;
        position: relative;
        &Content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            padding-top: 6%;
        }
    }
    &__Actions {
        margin: auto;
        width: fit-content;
        margin-top: -4%;
    }
}