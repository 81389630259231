@import "../../style/variables";

.Challenges{
    &__Container {
        margin-bottom: 40px;
    }
    &__Actions {
        text-align: right;
        button {
            margin-right: 12px;
        }
    }
    &__ActiveLabel {
        background-color: $status-ok;
        color: white;
        width: fit-content;
        padding: 4px 8px;
        border-radius: $radius;
    }
    &__InactiveLabel {
        background-color: $status-inactive;
        color: white;
        width: fit-content;
        padding: 4px 8px;
        border-radius: $radius;
    }
    &__DeactivatedLabel {
        background-color: $status-deactivated;
        color: white;
        width: fit-content;
        padding: 4px 8px;
        border-radius: $radius;
    }
}