
.ChallengeMap {
    &__Container {
        
    }
    &__MapCanvas {
        position: absolute;
        top: 0;
        left: 0;
    }
    &__Title {
        position:absolute;
        top: 1%;
        left: 1%;
        width: 25vw;
        aspect-ratio: 4;
        
        &Content {
            font-family: 'LondrinaRegular';
            font-weight: normal;

            color: #59194B;
            font-size: 3.4em;
            position: absolute;
            left: 8%;
            top: 16%;
        }
    }
    &__Loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &Inner {
            font-family: 'LondrinaRegular';
            font-size: 3em;
        }
    }
}