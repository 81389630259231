.InvalidAspect {
    &__Container {
        position:absolute;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 4;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__Content {
        padding: 10%;
        font-size: 20px;
        font-family: 'ShantelleSans';
        color: white;
        img {
            width: 200px;
            margin-bottom: 30px;
        }
    }
}