.ConfirmDialog {
    &__ExitIcon {
        position: absolute;
        right: -8%;
        top: -2%;
        cursor: pointer;
        width: 2em !important;
        height: 2em !important;
    }
    &__Content {
        p {
            font-size: 2em;
        }
        font-family: 'LondrinaRegular';
        color: #6B6B6B;
        padding-top: 13%;
    }
    &__Button {
        margin: auto;
        margin-top: 14%;
    }
}