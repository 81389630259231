@import '../../style/variables';

.SessionCompletionPage{
    &__Container {
        max-width: $page-width;
        overflow: auto;
        margin: auto;
        padding-top: 20px;
        padding-left: $page-padding;
        padding-right: $page-padding;
    }
    &__Leaderboard {
        margin-bottom: 60px;
        overflow: auto;
        max-width: calc(100vw - 58px);
    }
    &__ChallengeStatus {
        display: flex;
        flex-direction: column;

        height: 200px;
        display: flex;
    justify-content: center;
    align-items: center;
        div {
            text-align: center;
        }
    }
    &__ChallengeProgress {
        font-weight: normal;
        div {
            border: 1px solid grey;
            width: 100px;
            height: 12px;
        }
    }
    &__ActiveLabel {
        background-color: $status-ok;
        color: white;
        width: fit-content;
        padding: 2px 4px;
        border-radius: $radius;
        margin: auto;
        margin-top: 10px;
        font-size: 12px;
    }
    &__InactiveLabel {
        background-color: $status-inactive;
        color: white;
        width: fit-content;
        padding: 2px 4px;
        border-radius: $radius;
        margin: auto;
        margin-top: 10px;
        font-size: 12px;
    }
    &__DeactivatedLabel {
        background-color: $status-deactivated;
        color: white;
        width: fit-content;
        padding: 2px 4px;
        border-radius: $radius;
        font-size: 12px;
        margin-top: 10px;
    }
    &__ChallengeTitle {
        margin-bottom: 10px;
        font-size: 13px;
        flex-grow: 1;
        writing-mode: vertical-rl;
        rotate: 180deg;
        text-orientation: mixed;
        vertical-align: middle;
        display: table-cell;
        white-space: pre-wrap;
    }
    &__GameLink {
        margin-left: 2px;
        font-size: 10px;
        float: right;
    }
}