.GameSelectInput {
  &__Container {
    position: relative;
    width: 15vw;
    aspect-ratio: 5;
  }

  &__Background {
    position: absolute;
    left: 0;
    top: 0;
  }

  &__Input {
    position: absolute;
    left: 0;
    top: 12%;
    width: 13vw;
    left: 1vw;
    aspect-ratio: 5;
    background: transparent;
    padding-left: 0px;
    font-family: "RobotoSlab";
    font-size: 1.5em;
    border: 0;
    text-align: center;
    overflow: hidden;

    &:active,
    &:focus,
    &:focus-visible {
      outline: 0;
    }

    -webkit-appearance: none;
    -moz-appearance: none;

    text-indent: 1px;
    text-overflow: '';
  }

  &__Dropdown {
    position: absolute;
    right: 4%;
    top: 40%;
    width: 1.4vw;
    aspect-ratio: 1;
  }
}