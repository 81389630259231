@import "../../style/variables";

.Dropdown {
  &__Container {
    display: inline;
    position: relative;
  }

  &__Icon {
    margin-left: 8px;
  }

  &__Options {
    position: absolute;
    background-color: white;
    top: 30px;
    left: 0;
    min-width: 100%;
    text-align: left;
    box-shadow: $shadow;
  }

  &__Option {
    white-space: nowrap;
    font-family: "RobotoSlab";
    cursor: pointer;
    padding: 8px 12px;
    background-color: white;
    border: 0;
    width: 100%;
    text-align: left;
    margin-left: 0px !important;
  }

  &__Option:not(:last-child) {
    margin-bottom: 6px;
  }

  &__Option:hover {
    background-color: rgba(0, 0, 0, .1);
  }
}