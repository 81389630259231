@import "../../style/variables";

.Card {
    &__Container {
        box-shadow: $shadow;
        background-color: white;
        border-radius: $radius;
    }
    &__Content {
        padding: 20px;

        &--noPadding {
            padding: 0px;
        }
    }
    &__Footer {
        padding: 20px;
        background-color: $primary;
        color: white;
        border-radius: 0 0 $radius $radius;
    }
}