.GameScore {
    &__Container {
        position:absolute;
        right: 4%;
        top: 4%;
        color: white;
        font-weight: bolder;
        font-size: 2.3em;
        display: flex;
        align-items: center;
    }
    &__Score {
        margin-right: 2%;
        font-family: 'LondrinaLight';
        font-weight: lighter;
        color: #59194B;
       
    }
    &__IconScore {
        width: 4vw;
        aspect-ratio: 1;
    }
    &__IconLeaderboard {
        cursor: pointer;
        transition: scale 0.3s ease;
        &:hover {
            scale: 1.3;
        }
        width: 4vw;
        aspect-ratio: 1;
        margin-left: 20%;
    }
}