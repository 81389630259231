@import '../../style/variables';

.SessionDebriefPage{
    &__Container {
        max-width: $page-width;
        margin: auto;
        padding-top: 20px;
        padding-left: $page-padding;
        padding-right: $page-padding;
    }
    &__Content {
        margin-bottom: 60px;
    }
    &__Title {
        margin-top: 60px;
        color: $primary;
        font-family: 'LondrinaLight';
        font-size: 26px;
    }
    &__Groups {
        display: flex;
        flex-direction: row;
        gap: 20px;
        flex-wrap: wrap;
        margin-bottom: 40px;
    }

    &__Action .Card__Content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__Action {
        font-size: 24px;
        margin-bottom: 20px;
        b {
            color: $primary;
        }
    }
}