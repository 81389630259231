.GameDialog {
    &__Container {
        position: absolute;
        height: 100%;
        width: 100vw;
        left: 0;
        top: 0;
    }
    &__Overlay {
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        &--blurred {
            backdrop-filter: blur(4px);
        }
        animation-name: overlayEnter;
        animation-duration: 0.3s;
    }
    &__Dialog {
        width: 50vw;
        height: 45vw;
        display: flex;
        flex-direction: column;
        button {
            font-size: 1.5em;
        }
        &--xSmall {
            width: 30vw;
            height: 15vw;
        }
        &--small {
            width: 40vw;
            height: 30vw;
        }
        &--big {
            width: 80vw;
            height: 60vw;
        }
        animation-timing-function: ease;
        animation-name: dialogEnter;
        animation-duration: 0.3s;
    }
}

@keyframes overlayEnter {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }

  @keyframes dialogEnter {
    0% {
        opacity: 0;
        translate: 0% 100%;
    }
    100% {
        opacity: 1;
        translate: 0% 0%;
    }
  }