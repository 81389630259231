.TutorialDialog {
  &__Container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__Background {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__Content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    h3 {
      color: #59194b;
      margin-bottom: 1%;
    }
  }

  &__Column {
    margin: 2%;
    padding: 2%;
    padding-top: 0;
    font-family: "ShantelleSans";
    overflow: auto;
    text-align: left;
    max-width: 100%;
    max-height: 100%;
    width: 90vw;

  }

  &__WelcomeText {
    color: white;
    font-family: 'LondrinaRegular';
    font-size: 2em;
    position: absolute;
    left: 50%;
    translate: -50%;
    top: -50px;
    width: 100vw;
    padding: 0 40px;
  }

  &__NextButton {
    position: fixed;
    bottom: 3%;
    right: 5%;

    button {
      width: 100%;
    }
  }

  &__Intro {
    font-size: 1.2em;

    
  }

  &__Areas {
    display: flex;
    flex-direction: row;
  }

  &__AreaGroup {
    width: 50%;
  }

  &__Area {
    margin: 2%;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.1));

    &Header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 6%;
      margin-top: 2%;
      display: block;
    }

    &Title {
      margin-left: 2%;
      margin-top: 2%;
      font-size: 1.4em;
    }

    padding: 4%;
  }

  &__AreaDesc {
    margin-top: 1em;
    text-align: left;
    font-size: 1em;
  }

  &__Location {
    font-weight: bolder;
    color: #59194b;
    margin-top: 1%;
    display: inline-block;
  }

  &__Instructions {
    font-size: 1.2em;
    padding-left: 2%;
    padding-right: 2%;

    h3 {
      font-weight: bolder;
      font-size: 1.6em;
      color: #59194b;
      margin-top: 1%;
    }
  }

  &__ExitIcon {
    position: absolute;
    right: 1%;
    top: 2%;
    cursor: pointer;
    width: 2vw;
    z-index: 2;
  }
}