.TestedSolutionDialog {
    &__Container {
        position: relative;
    }
    &__Box {
        width: 38vw;
    }
    &__Close {
        position:absolute;
        top:0;
        right: -4%;
        cursor: pointer;
        width: 2vw;
    }
    &__Icon {
        position:absolute;
        left: 50%;
        translate: -50%;
        top: -20%;
        width: 7vw;
    }
    &__BottomLabel {
        position: absolute;
        bottom: -6vw;
        left: 0;
        width: 100%;

        img {
            width: 100%;
            aspect-ratio: 8;
        }
        &Content {
            color: white;
            position: absolute;
            top: 0;
            width: 100%;
            margin-top: 4%;
            font-size: 1.4em;
            font-family: 'ShantelleSans';
        }
    }
    &__Content {
        color: white;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &Header {
            font-family: 'LondrinaRegular';
            font-size: 2em;
            margin: 0 14%;
        }
        &Body {
            font-size: 1.4em;
            font-family: 'ShantelleSans';
            margin-top: 1%;
        }
    }
}