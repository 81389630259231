@import "../../style/variables";
@import "../../../admin/style/variables";

.HomePage {
  &__Container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__Content {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    &Left {
      width: 30%;
      padding-top: 2%;
      font-family: "ShantelleSans";
      color: white;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &Center {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &Bottom {
        margin-top: 8%;
        color: white;
        font-family: "LondrinaLight";
        font-weight: lighter;
        font-size: 1.3em;

        & a {
          color: white;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &Right {
      width: 30%;
    }
  }

  &__SelectGameInput {
    padding-top: 4%;
  }

  &__Input {
    margin: auto;
  }

  &__TokenNotFound {
    margin-top: 5%;
    font-family: 'ShantelleSans';
    color: #851414;
    font-size: 1.3em;
  }

  &__Icon {
    width: 25%;
    height: 100%;
    margin-left: 8%;
  }

  &__FacultyAuthor {
    margin-left: 2em;
    margin-bottom: 4em;

    &Title {
      font-family: 'LondrinaLight';
      font-size: 1.8em;
    }

    &Name {
      font-family: 'LondrinaRegular';
      font-size: 2em;
    }
  }
}