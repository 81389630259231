.SessionsTable {
    &__Container {
        margin-bottom: 40px;
    }
    &__Actions {
        text-align: right;
        button {
            margin-right: 12px;
        }
    }
    &__Loading {
      font-size: 18px;
      font-weight: bold;
    }
}