.LeaderboardPage {
    &__Actions {
        position:absolute;
        right: 10%;
        bottom: 4%;
    }
    &__Container {
        flex-grow: 1;
        display: flex;
        height: 100%;
    }
    &__LeftColumn {
        width: 50%;
    }
    &__RightColumn {
        width: 50%;
    }
    &__MyScore {
        margin: auto;
        position: relative;
        width: 80%;
        &Image {
            width: 100%;
            top: 0;
        }
        &Label {
            position: absolute;
            img {
                width: 16vw;
            }
            left: -2%;
            top: 5%;
            &Text {
                font-family: 'LondrinaRegular';
                color: white;
                position: absolute;
                top: 6%;
                left: 8%;
                font-size: 2.8em;
            }
        }
        &ScoreContainer {
            position: absolute;
            bottom: 0px;
            
            font-size: 2.4em;
            
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
        }
        &Nickname {
            color: white;
            font-family: 'LondrinaLight';
            font-weight: bolder;
            text-align: center;
            flex-grow: 1;
        }
        &Score {
            margin-left: 10%;
            margin-right: 10%;
            position: relative;
            img {
                width: 9vw;
            }
            &Text {
                color: white;
                font-family: 'LondrinaLight';
                font-weight: bolder;
                position: absolute;
                text-align: center;
                left: 0;
                top: 25%;
                width: 90%;
                font-size: 1.4em;
            }
            &Icon {
                position: absolute;
                top: -10%;
                right: -10%;
                img {
                    width: 4vw;
                }
            }
        }
    }

}

.ExitButton {
    &__Container {
        position: relative;
        img {
            width: 4vw;
            position: absolute;
            right: -20%;
            top: -10%;
        }
    }
}