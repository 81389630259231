@import '../../style/variables';

.DebriefChart {
    &__Container {
        max-width: $page-width;
        margin: auto;
        padding-top: 20px;
        padding-left: $page-padding;
        padding-right: $page-padding;
    }
    &__GroupFilter {
        margin-top: 30px;
        &Label {
            font-family: "ShantelleSans";
            font-weight: bold;
            margin-bottom: 10px;
        }
        &>button {
            display: block;
            margin-bottom: 10px;
        }
    }
    &__Title {
        margin-top: 60px;
        color: $primary;
        font-family: 'LondrinaLight';
        font-size: 26px;
    }
    &__NoData {
        font-family: "ShantelleSans";
        font-size: 24px;
        margin-top: 20%;
        width: 100%;
        text-align: center;
    }
    &__Actions {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 140px;
    }
    &__Challenge {
      font-size: 34px;
      font-family: 'LondrinaRegular';
      color: $primary;
    }
}