.Solutions {
  &__Container {
    height: 98%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    padding-bottom: 10%;
    align-items: flex-start;
  }

  &__Solution {
    width: 31%;
    aspect-ratio: 1.1;
    display: flex;
    flex-direction: column;
    position: relative;

    &Container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      padding-bottom: 0px;
      display: flex;
      flex-direction: column;
    }

    &Title {
      font-family: 'LondrinaRegular';
      font-weight: bolder;
      text-align: left;
      font-size: 1.5em;
      margin-bottom: 2%;
      padding-top: 8%;
      padding-left: 8%;
    }

    &Description {
      font-family: 'ShantelleSans';
      text-align: left;
      font-size: 1em;
      padding: 0 8%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 30%;
      white-space: pre-wrap;
    }

    &Actions {

      position: absolute;
      translate: 0 30%;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &Score {
      position: absolute;
      right: -6%;
      bottom: 20%;

      img {
        width: 3vw;
      }

      &Content {
        position: absolute;
        top: 6%;
        left: 20%;
        color: white;
        font-family: 'LondrinaRegular';
        font-size: 1.6em;
      }
    }
  }
}

.Button {
  &__Container {
    width: 3.4vw;

    img {
      width: 3.4vw
    }

    position: relative;

    button {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      width: 100%;
    }
  }
}