.InfoButton {
    &__Container {
        position:absolute;
        left: 4%;
        bottom: 6%;
        width: 3vw;
        aspect-ratio: 1;
        cursor: pointer;
        transition: scale 0.3s ease;
        &:hover {
            scale: 1.3;
        }
    }
}