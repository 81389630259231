.Snackbar {
    &__Container {
        position: fixed;
        color: white;
        top: 120px;
        right: 20px;
        opacity: 1;
        transition: all 0.2s ease-in;

        &--hidden {
            right: -100px;
            opacity: 0;
        }
    }
    &__Content {

        padding: 20px;

        &--success {
            background-color: green;
        }
        &--danger {
            background-color: #eba00c;
        }
        &--error {
            background-color: #b32d24;
        }
    }
}