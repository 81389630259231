.AcknowledgeDialog {
  &__Content {
    font-family: 'ShantelleSans';
    padding: 0 2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 18vw;
  }

  &__Row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__Icon {
    margin-right: 3%;
  }

  &__Text {
    font-size: 1.2em;
    text-align: left;
    width: 100%;

    &--center {
      text-align: center;
    }

    &--larger {
      font-size: 1.6em;
      margin-top: 3%;
    }
  }
}