.ChallengePage {
    &__Container {
        display: flex;
        justify-content: center;
        align-items: stretch;
        height: 100%;
    }
    &__Content {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 170vh;
        height: 100%;
    }
    &__Left {
        width: 50%;
        text-align: center;
        height: 100%;
    }
    &__DescrBackground {
        height: 100%;
        margin-left: 10%;
        display: flex;
        justify-content: stretch;
        align-items: center;
    }
    &__DescCard {
        height: 80%;
        width: 100%;
        margin: 6%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    &__Right {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        &Header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 2vw;
            margin-left: 2vw;
            align-items: center;
            &Text {
                font-family: 'ShantelleSans';
                color: white;
                font-size:1.4em;
                font-weight: bolder;
                overflow: auto;
            }
            &Actions {
                position: relative;
                right: initial;
                top: initial;
            }
        }
        &Solutions {
            flex-grow: 1;
        }
    }
    
    &__Video {
        video {
            margin: 6%;
            max-width: 90%;
        }
    }
    &__Separator {
        &--withMargin {

            margin-top: 12%;
        }
        img {
            width: 4vw;
        }
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 5%;
        &Icon {
            
            &--rotated {
                transform: rotate(180deg);
            }
        }
        &Center {
            margin-left: 2%;
            margin-right: 2%;
            font-family: 'LondrinaRegular';
            font-size: 2em;
        }
    }

    &__DescriptionPreview {
        flex-grow: 1;
        max-width: 100%;
        padding-top: 5%;
        padding-left: 10%;
        padding-right: 10%;
        overflow: hidden;
        font-family: 'ShantelleSans';
        font-size: 1.2em;
        max-height: 25vh;
        position: relative;
        text-align: left;
        white-space: pre-wrap;
        &Bottom {
            position: absolute;
            bottom: 0px;
            left: 20px;
            right: 20px;
            width: 90%;
            transform: rotate(-1deg);
        }
    }

    &__Description {
        padding-left: 10%;
        padding-right: 10%;
        font-family: 'ShantelleSans';
        font-size: 1.2em;
        text-align: left;
        white-space: pre-wrap;
        max-height: 60vh;
        overflow: auto;
        padding-bottom: 10%;
    }

    &__ShowDescriptionButtonContainer {
        position:absolute !important;
        bottom: -5%;
        text-align: center;
        width: 100%;
    }

    &__ShowDescriptionButton {
        margin: auto;
    }

    &__ChallengeTitle {
        position: absolute;
        top: 4%;
        left: -10%;
        img {
            width: 8vw;
        }
        &Text {
            position: absolute;
            top: 20%;
            left: 20%;
            color: white;
            font-family: 'LondrinaRegular';
            font-size: 1.2em;
        }
    }
}