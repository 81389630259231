.Leaders {
    &__Container {
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
    }
    &__Title {
        margin-top: 2%;
        position: relative;
        img {
            transform: rotateZ(2deg);
            width: 10vw;
        }
        &Content {
            position:absolute;
            top: 10%;
            left: 50%;
            translate: -50%;
            font-family: 'LondrinaRegular';
            font-size: 2em;
            color: white;
        }
    }
    &__Content {
        margin-left: 2%;
        margin-right: 2%;
        margin-top: 4%;
        flex-grow: 1;
        width: 90%;
        max-width: 90%;
        display: flex;
        flex-direction: row;
        &Column {
            width: calc(50% - 30px);
            margin-right: 6%;
        }
    }
    
}

.Leader {
    &__Container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10%;
    }
    &__Text {
        font-family: 'LondrinaRegular';
        font-size: 2em;
        color: white;
        flex-grow: 1;
        text-align: left;
    }
    &__Icon {
        position: relative;
        img {
            width: 4vw;
        }
        &Content {
            position: absolute;
            width: 100%;
            text-align: center;
            top: 18%;
            font-family: 'LondrinaRegular';
            font-size: 2em;
            color: white;
            flex-grow: 1;
        }
    }
}