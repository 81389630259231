@import '../../style/variables';

.SessionDebriefCorrelation {
  &__Container {
    max-width: $page-width;
    margin: auto;
    padding-top: 20px;
    padding-left: $page-padding;
    padding-right: $page-padding;
  }

  &__GroupFilter {
    &Label {
      font-family: "ShantelleSans";
      font-weight: bold;
      margin-bottom: 10px;
    }

    &>button {
      display: block;
      margin-bottom: 10px;
    }
  }

  &__Group {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    gap: 10px;
  }

  &__GroupDot {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid black;
    opacity: .7;
  }

  &__Title {
    margin-top: 60px;
    color: $primary;
    font-family: 'LondrinaLight';
    font-size: 26px;
  }

  &__NoData {
    font-family: "ShantelleSans";
    font-size: 24px;
    margin-top: 20%;
    width: 100%;
    text-align: center;
  }
}