.MapNavigator {
    &__Container {
        position: absolute;
        width: 19vw;
        right: 1%;
        bottom: 1%;
        img {
            width: 100%;
        }
    }

    &__MapControls {
        position: absolute;
        top: -93%;
        width: 19vw; 
        aspect-ratio:1.4;
        &Inner {
            position: relative;
            transition: translate 0.3s ease-in-out;
            width: 100%;
            aspect-ratio: 1.4;
        }
        &Inner--closed {
            
            translate: 0 72.5%;
        }
        &Inner--open {
            translate: 0 0%;
        }
    }

    &__IconRotated {
        transform: rotate(-180deg);
    }
    &__Button {
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        transition: scale 0.3s ease;
        &:hover {
            scale: 1.1;
        }

        &ToggleControls {
            position: absolute;
            top: 0;
            right: 0;
            width: 55%;
            height: 20%;
            font-family: 'LondrinaLight';
            color: white;
            font-size: 1.3em;
            img {
                transition: all 0.3s ease-in-out;
                width: 1em;
                margin-left: 0.3em;
                margin-top: 0.3em;
            }
        }


        &ZoomIn {
            top: 40%;
            left: 10%;
            width: 28%;
            height: 24%;
            background-color: transparent;
        }
        &ZoomOut {
            top: 40%;
            left: 28%;
            width: 28%;
            height: 24%;
            background-color: transparent;
        }
        &MoveUp {
            top: 31%;
            left: 68%;
            width: 18%;
            height: 16%;
            background-color: transparent;
        }
        &MoveDown {
            top: 50%;
            left: 68%;
            width: 19%;
            height: 17%;
            background-color: transparent;
        }
        &MoveLeft {
            top: 42%;
            left: 60%;
            width: 24%;
            height: 13%;
            background-color: transparent;
        }
        &MoveRight {
            top: 42%;
            left: 74%;
            width: 24%;
            height: 13%;
            background-color: transparent;
        }
    }
    &__Selector {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}