@import "../../style/variables";

.Button{
    padding: 8px 12px;
    background-color: $primary;
    color: white;
    font-size: $font-size-medium;
    border: 0px;
    cursor: pointer;
    border-radius: $radius;
    font-family: 'RobotoSlab';
    white-space: nowrap;

    &--danger {
        background-color: $danger;
    }

    &--disabled {
        pointer-events: none;
        background-color: $disabled;
    }

    &--inactive {
        background-color: $disabled;
    }

    &--outline {
        background-color: $background;
        color: $primary;
    }

    &__Icon {
        margin-right: 12px;
        display: inline;
    }
}