.SolutionDialog {
  &__Container {
    position: relative;
  }

  &__Wrapper {
    position: relative;
    margin: auto;
    width: fit-content;
    width: 40vw;
    aspect-ratio: 1;
  }

  &__Content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
  }

  &__Title {
    font-family: 'LondrinaRegular';
    padding: 6%;
    padding-top: 10%;
    font-size: 2.2em;
  }

  &__Description {
    text-align: left;
    font-family: 'ShantelleSans';
    font-size: 1.2em;
    margin: 0 10%;
    padding-top: 0;
    flex-grow: 1;
    overflow: auto;
  }

  &__Actions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 8% 20%;
  }

  &__ExitIcon {
    position: absolute;
    right: -6%;
    top: 0%;
    width: 2vw;
    cursor: pointer;
  }

  &__Points {
    font-family: 'ShantelleSans';
    color: white;
    position: absolute;
    bottom: 5%;
    width: 110%;
    left: -4%;

    img {
      aspect-ratio: 8;
    }

    &Content {
      position: absolute;
      left: 0;
      top: 30%;
      right: 0;
      bottom: 0;

      font-size: 120%;
    }
  }
}

.Button {
  &__Container {
    position: relative;

    img {
      width: 100%;
    }

    button {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      width: 100%;
    }
  }

  &__Description {
    font-family: 'LondrinaRegular';
    color: grey;
    font-size: 1.6em;
  }
}

.Label {
  &__Container {
    position: absolute;
    left: -120%;
    width: 12vw !important;

    img {
      width: 100% !important;
    }
  }

  &__Content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 6%;
    font-family: 'ShantelleSans';
    color: #C78B43;
    font-weight: bold;
  }
}