
.AreaSelector {
    &__Container {
        color: white;
        font-weight: bolder;
        width: 18vw;
        height: 14vw;
        position: relative;
    }
    &__ButtonOk {
        cursor: pointer;
        transition: scale 0.3s ease;
        &:hover {
            scale: 1.3;
        }
    }
    &__Title {
        color: black;
        position: absolute;
        bottom: 10vw;
        width: 100%;
        font-size: 1.8em;
        font-family: 'LondrinaLight';
        color: #59194B;
        text-align: center;
    }
    &__ApplyButton {
        position: absolute;
        right: 7.5vw;
        bottom: 2.5vw;
    }
}

.AreaSwitcher {
    &__Container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__Button {
        cursor: pointer;
        transition: scale 0.3s ease;
        &:hover {
            scale: 1.3;
        }
        &--rotated {
            transform: rotateZ(180deg);
        }
    }
    &__Left {
        position: absolute;
        bottom: 2.5vw;
        left: 2vw;
        width: 3.1em;

    }
    &__Right {
        position: absolute;
        right: 2vw;
        bottom: 2.5vw;
        width: 3.1em;

    }
    &__AreaContainer {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 5.5vw;
        margin: auto;
        text-align: center;
        user-select: none;
        font-family: 'ShantelleSans';
        font-weight: lighter;
        font-size: 1em;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    &__Area {
        color: white;
        vertical-align: middle;
        white-space: initial;
        margin-top: -4%;
    }
}