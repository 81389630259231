.UserResolver {
  &__Container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__Content {
    display: flex;
    flex-direction: column;
    margin: 4%;
    font-family: 'LondrinaLight';
    font-size: 1.8em;
    max-height: 85%;
  }

  &__Games {
    overflow-y: auto;

    ul {
      list-style-type: none;
      padding-left: 0;
    }
  }

  &__NoGames {
    margin-top: 30%;
  }
}