@import '../../style/variables';

.SessionLeaderboardPage{
    &__Container {
        max-width: $page-width;
        margin: auto;
        padding-top: 20px;
        padding-left: $page-padding;
        padding-right: $page-padding;
    }
    &__Leaderboard {
        margin-bottom: 60px;
    }
}