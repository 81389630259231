@import '../../../style/variables.scss';

.TextInput {
    &__Container {
        margin: 12px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &__Label {
        font-weight: bold;
        margin-right: 8px;
        width: 160px;
        min-width: fit-content;
        display: block;
    }
    &__Input {
        height: 40px;
        padding: 4px 12px;
        display: block;
        border: 1px solid grey;
        background-color: #FFF;
        color: #000;
        font-size: $font-size-medium;
        border-radius: $radius;

        &File {
            padding: 12px 8px 0px 8px;
            
        }
    }
    &__Error {
        color: $danger;
    }
}