@import '../../style/variables';

.Breadcrumb {
    &__Container {
        text-align: left;
        
        a {
            color: $primary;
            text-decoration: none;
        }
    }

    &__Title {
        margin-top: 16px;
        color: $primary;
        font-family: 'LondrinaLight';
        font-size: 26px;
    }
}