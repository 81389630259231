.HalfwayCheck {
  &__Container {
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__Content {
    position: relative;
    width: 40vw;
    aspect-ratio: 2;

    &Inner {
      position: absolute;
      left: 0;
      top: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 8%;
      font-family: 'ShantelleSans';
      font-size: 1.3em;
    }
  }

  &__NextButton {
    position: absolute;
    right: 2vw;
    bottom: 4vw;

    button {
      width: 100%;
    }
  }
}