.AppliedSolution {
  &__Container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__Content {
    position: relative;
    width: fit-content;
    height: fit-content;
    width: 40%;
    aspect-ratio: 12;

    &>img {
      width: 38vw;
    }

    &Inner {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__Text {
    padding-top: 8%;
    font-family: 'LondrinaLight';
    font-size: 1.4em;
  }

  &__ChallengeTitle {
    position: absolute;
    top: 8%;
    left: -2%;

    img {
      width: 8vw;
      aspect-ratio: 3
    }

    &Text {
      position: absolute;
      top: 18%;
      right: 10%;
      color: white;
      font-family: 'LondrinaRegular';
      font-size: 1.5em;
    }
  }

  &__Points {
    font-family: 'ShantelleSans';
    color: white;
    font-size: 1.5em;
    position: absolute;
    bottom: 2%;
    width: 100%;

    &>img {
      width: 80%;
      aspect-ratio: 8;
    }

    &Content {

      position: absolute;
      left: 0;
      top: 20%;
      right: 0;
      bottom: 0;

    }
  }

  &__NextButton {
    position: absolute;
    right: 2vw;
    bottom: 4vw;

    button {
      width: 100%;
    }
  }

  &__PointsEquation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 4%;

    &Element {
      position: relative;
      width: 14%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &Upper {
        height: 50%;

      }

      &Lower {
        padding-top: 10%;
        height: 50%;
        font-family: 'LondrinaLight';
        font-size: 1em;
      }
    }
  }
}

.ScoreCircle {
  &__Container {
    position: relative;
    width: fit-content;
    margin: auto;
    margin-top: 10%;

    &>img {
      width: 4.3vw;
    }
  }

  &__Content {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -55% -55%;
    font-family: 'LondrinaRegular';
    color: white;
    font-size: 2.6em;
  }
}

.NetCircle {
  &__Container {
    position: relative;
    width: fit-content;
    margin: auto;

    &>img {
      width: 5.5vw;
    }
  }

  &__Content {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -90% -45%;
    font-family: 'LondrinaRegular';
    color: white;
    font-size: 2.6em;
  }
}

.Operator {
  &__Container {
    top: 35%;
    left: 50%;
    position: absolute;
    translate: -50% -50%;

    &>img {
      width: 3vw;
    }
  }
}