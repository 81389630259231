.Actions {
    text-align: right;
    padding-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    &__Right {
        flex-grow: 1;
    }
    button:not(:first-child) {
        margin-left: 2px;
    }
}