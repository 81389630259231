.GroupCard {
  &__Container {
    width: calc(16.5% - 15px);
    min-width: 170px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 12px;

    & .Card__Content {
      padding: 0 !important;
    }
  }

  &__Container:not(:first-child) {
    .GroupCard__Trophy {
      opacity: 0;
    }
  }

  &__Trophy {
    font-size: 60px;
    margin-top: -60px;
  }

  &__Score {
    font-family: 'LondrinaRegular';
    font-size: 40px;
    margin-top: 20px;
  }
}