@font-face {
    font-family: 'LondrinaLight';
    src: URL('./fonts/Londrina_Solid/LondrinaSolid-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'LondrinaRegular';
    src: URL('./fonts/Londrina_Solid/LondrinaSolid-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'ShantelleSansItalic';
    src: URL('./fonts/Shantell_Sans/ShantellSans-Italic-VariableFont_BNCE,INFM,SPAC,wght.ttf') format('truetype');
}

@font-face {
    font-family: 'ShantelleSans';
    src: URL('./fonts/Shantell_Sans/ShantellSans-VariableFont_BNCE,INFM,SPAC,wght.ttf') format('truetype');
}

@font-face {
    font-family: 'RobotoSlab';
    src: URL('./fonts/Roboto_Slab/RobotoSlab-VariableFont_wght.ttf') format('truetype');
}

.AnimatedButton {
    transition: scale 0.3s ease;
    &:hover {
        scale: 1.3;
    }
}
