@import '../../style/variables';

.GameButton {
    &__Container {
        position: relative;
        transition: scale 0.3s ease;
        width: 10vw;
        aspect-ratio: 3;
        &:hover {
            scale: 1.3;
        }
    }
    &__Button {
        width: 100%;
        cursor: pointer;
        position:absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: transparent;
        border: 0;
        color: white;
        font-family: "LondrinaRegular";
        text-transform: uppercase;
    }
}