.ProfilationPage {
  &__Welcome {
    position: absolute;
    color: white;
    font-size: 2em;
    z-index: 2;
    font-family: 'LondrinaLight';
    width: 100%;
    text-align: center;
    top: 7%;
  }

  &__Back {
    position: absolute;
    color: white;
    font-size: 1.4em;
    z-index: 2;
    font-family: 'LondrinaLight';
    cursor: pointer;
    width: 100%;
    bottom: 4%;
  }

  &__Container {
    flex-grow: 1;
    display: flex;
    height: 100%;
  }

  &__DialogContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 28vw;

    &Label {
      margin-bottom: 0.6em;
      font-size: 2.4em;
      font-family: 'LondrinaRegular';
      color: #6B6B6B;
    }

    &Info {
      margin-top: 0.4em;
      max-width: 80%;
      color: #6B6B6B;
      font-family: 'ShantelleSans';
      font-size: 1.6em;
    }

    &Field {
      &Error {
        color: #ad2323;
        font-family: 'ShantelleSans';
        font-size: 1em;
      }
    }
  }
}